$primary-color: #4c8577;
$secondary-color: rgba(76, 133, 119, 0.25);
$background-color: #ffffff;
$font-family: "Playfair Display";
$font-size: 32px;
$font-weight: 700;
$line-height: 150%;
$border-width: 1px;
$border-color: $primary-color;

.fc {
  & .fc-button-primary {
    /*styles for  calendar buttons : week, arrow buttons*/
    color: #000000;
    background: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    &:hover,
    &:active {
      background-color: #fff;
      color: #9a9a9a;
      border: 1px solid #9a9a9a;
    }
  }

  & .fc-button-primary:not(:disabled).fc-button-active {
    /*Handle button active behavior : month, week */
    display: flex;
    height: 64px;
    justify-content: center;
    align-items: center;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #82aaa0;
    color: #4c8577;
    background: rgba(76, 133, 119, 0.25);
  }

  & .fc-toolbar-title {
    /*Heading title*/
    color: #000;
    font-family: $font-family;
    font-size: $font-size;
    font-style: normal;
    font-weight: $font-weight;
    line-height: $line-height;
  }

  &-scrollgrid-sync-inner > .fc-col-header-cell-cushion {
    /*Days of week style changes: Sun, Mon, Tue, Wed, ...*/
    display: inline-flex;
    background: #98989812;
    border-radius: 56px;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &-scrollgrid {
    /*remove border calendar table*/
    border: none !important;
  }

  &-theme-standard {
    /*Style changes of calendar table th, td*/
    th {
      border: none !important;
      padding-bottom: 6px;
    }

    td {
      border-right: none !important;
      border-bottom: none !important;
    }
  }

  & .fc-daygrid-day-top {
    /*left alignment of days*/
    flex-direction: revert-layer;
  }

  & .fc-daygrid-day-number {
    /*style changes of the days : 1st, 2nd, 3rd ...*/
    // color: #1d1a1a;  //enable the following font color by disabling this
    font-size: 18;
    font-family: Poppins;
    font-weight: 500;
    word-wrap: break-word;
  }

  & .fc-daygrid-day.fc-day-today {
    /*Change the cell color of current date*/
    background-color: #d7717124;
    color: #d77171 !important;
  }

  & .fc-daygrid-day-frame {
    /*Cell sizes changes*/
    //width: 166px;
    width: 100%;
    height: 176px;
    padding: 15px 13px;
  }
  @media (max-width: 900px) {
    /* Styles for medium screens */
    &-scrollgrid-sync-inner > .fc-col-header-cell-cushion {
      /*Days of week style changes: Sun, Mon, Tue, Wed, ...*/
      padding: 8px 20px;
    }
  }
}

.fc-button-group > .fc-button:not(:last-child) {
  /*remove the outline border of button: week, month, arrow icons*/
  border: none;
}

.fc-event {
  /*Remove default background color and border from event boxes*/
  background-color: white;
  border: white;
}

//customizations
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus,
.fc-button-primary:focus {
  box-shadow: none;
  background-color: #cddbd8;
  border: 3px solid #82aaa0;
  color: #4c8577;
}
button.fc-next-button.fc-button.fc-button-primary{
  border: 3px solid white;
}
button.fc-prev-button.fc-button.fc-button-primary{
  border: 3px solid white;
}
.fc .fc-button-primary:focus {
  box-shadow: none;
}

.fc-next-button.fc-button.fc-button-primary:active {
  background-color: white;
  color: black;
}
.fc-prev-button.fc-button.fc-button-primary:active {
  background-color: white;
  color: black;
}
.fc-next-button.fc-button.fc-button-primary {
  color: black;
}

// .fc-timeGridWeek-button.fc-button.fc-button-primary {
//   color: gray;
//   border: 1px solid gray;
// }
.fc-next-button.fc-button.fc-button-primary {
  border-color: white;
}
.fc-next-button.fc-button.fc-button-primary:active {
  border-color: white;
}
.fc-dayGridMonth-button.fc-button.fc-button-primary:active {
  background-color: #cddbd8;
  border: 3px solid #82aaa0;
  color: #4c8577;
}
.fc-dayGridMonth-button.fc-button.fc-button-primary {
  color: gray;
  border: 1px solid #9a9a9a;
}
button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active,
button.fc-dayGridWeek-button.fc-button.fc-button-primary.fc-button-active,
button.fc-dayGridWeek-button.fc-button.fc-button-primary,
button.fc-dayGridMonth-button.fc-button.fc-button-primary {
  font-size: 18px;
  height: 50px;
}
.fc-timeGridWeek-button.fc-button.fc-button-primary:active {
  border: 3px solid #82aaa0;
  background: #cddbd8;
  color: #4c8577;
}
button.fc-dayGridWeek-button.fc-button.fc-button-primary.fc-button-active {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 3px solid #82aaa0;
}
.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active {
  border: 3px solid #82aaa0;
}

.fc-dayGridMonth-button.fc-button.fc-button-primary {
  text-transform: capitalize !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.fc-dayGridWeek-button.fc-button.fc-button-primary {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  text-transform: capitalize !important;
  color: gray;
  border: 1px solid gray;
}
.fc-dayGridDay-button.fc-button.fc-button-primary {
  text-transform: capitalize !important;
}

.fc-icon.fc-icon-chevron-left:hover {
  color: black;
}

.fc-prev-button.fc-button.fc-button-primary:hover {
  color: black;
}

.fc .fc-toolbar.fc-header-toolbar {
  border-bottom: 1px solid rgb(230, 227, 225);
  padding-bottom: 2%;
}
@media (max-width: 600px) {
  /* Styles for medium screens */
  .fc .fc-toolbar {
    flex-direction: column;
    text-align: center;
  }
  //remove scroll bar and fixed height issues which occured when navigate to event view from sidebar
  .fc-view-harness.fc-view-harness-active {
    min-height: 180px;
  }
  .fc-scroller {
    overflow: hidden !important;
  }
  table.fc-col-header {
    width: 100% !important;
  }
  .fc-daygrid-body.fc-daygrid-body-unbalanced {
    width: 100% !important;
  }
  table.fc-scrollgrid-sync-table {
    width: 100% !important;
  }
  .fc .fc-toolbar-title {
    font-size: 18px;
  }
}

.fc-daygrid-more-link.fc-more-link {
  background: #ebebeb;
  padding: 8px;
  border-radius: 16px;
  color: #7f7f7f;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  bottom: 108px;
  left: 79px;
}

.confirmed-status {
  color: #fff;
}
.backup-status {
  color: #fff;
}
